import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxJsonViewerComponent } from './ngx-json-viewer/ngx-json-viewer.component';
export class NgxJsonViewerModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [
    CommonModule
  ],
  declarations: [
    NgxJsonViewerComponent
  ],
  exports: [
    NgxJsonViewerComponent
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function NgxJsonViewerModule_tsickle_Closure_declarations() {
/** @type {?} */
NgxJsonViewerModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NgxJsonViewerModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
